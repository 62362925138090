.heroContainer {
    background-image: radial-gradient(ellipse at 50% 55%, var(--mantine-color-violet-3) 0%, transparent 100%);
}

.featureGradient {
    background-image: radial-gradient(circle at 50% 50%, var(--mantine-color-violet-2) 0%, transparent 60%);
}

.ctaContainer {
    background-image:
        radial-gradient(circle at 70% 0%, var(--mantine-color-dark-9) 0%, transparent 30%),
        radial-gradient(circle at 80% 120%, var(--mantine-color-primary-3) 0%, transparent 50%),
        radial-gradient(circle at 100% 0%, var(--mantine-color-violet-2) 0%, transparent 30%),
        radial-gradient(ellipse at 60% 130%, var(--mantine-color-violet-3) 0%, transparent 50%),
        radial-gradient(ellipse at 40% 130%, var(--mantine-color-violet-3) 0%, transparent 50%);
}